import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

class Contact extends Component {
    render() {
        return (
            <div>
                <Header />

                <Helmet>
                    <title>Contact Page: #OpenToWorkRemote</title>
                    <meta name="description" content="Get in touch with me to discuss adding, removing, or featuring a job on #OpenToWorkRemote. I'm here to help." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/contact" />
                </Helmet>

                <div className="page-content bg-white">

                    <div className="dez-bnr-inr" 
                        style={{
                            height: "200px",
                            background: "linear-gradient(135deg, #2c3e50 0%, #3498db 100%)",
                            display: "flex",
                            alignItems: "center"
                        }}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry text-center">
                                <h1 className="text-white" 
                                    style={{
                                        margin: "0",
                                        fontSize: "2.5rem",
                                        fontWeight: "600",
                                        textShadow: "2px 2px 4px rgba(0,0,0,0.2)"
                                    }}>
                                    Welcome! Let's Connect
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div className="content-area py-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="blog-post blog-single blog-style-1 shadow-sm rounded p-4">
                                        <div className="dez-post-title">
                                            <h2 className="post-title m-t0 text-center mb-4">
                                                Hey, There! <span role="img" aria-label="happy person saying hi">😃 👋</span> I'm Mauro.
                                            </h2>
                                        </div>
                                        
                                        <div className="text-center mb-4"> 
                                            <img 
                                                src={require("./../../images/blog/default/mnb2.png")} 
                                                alt="Hi, I'm Mauro"
                                                className="img-fluid rounded-circle"
                                                style={{maxWidth: "250px"}}
                                            />
                                        </div>
                                        
                                        <div className="dez-post-text">
                                            <h4 className="mb-3">You can find me in any of the following channels:</h4>
                                            <div className="social-links mb-4">
                                                <p className="mb-3">
                                                    Feel free to connect with me on{' '}
                                                    <a href="mailto:mauro.bonfietti@gmail.com" className="btn btn-outline-primary btn-sm mx-1">
                                                        Email
                                                    </a>
                                                    <a href="https://www.linkedin.com/in/mauro-bonfietti/" className="btn btn-outline-primary btn-sm mx-1">
                                                        LinkedIn
                                                    </a>
                                                    <a href="https://x.com/maurobonfietti" className="btn btn-outline-primary btn-sm mx-1">
                                                        X
                                                    </a>
                                                </p>
                                            </div>
                                            
                                            <p className="mb-4">
                                                Check out my <Link to={"/blog"} className="btn btn-outline-primary btn-sm">Blog</Link>
                                                {' '}and more links{' '}
                                                <a href="https://linktr.ee/maurobonfietti" className="btn btn-outline-primary btn-sm">
                                                    About Me
                                                </a>
                                            </p>
                                            
                                            <p className="text-center mt-4">
                                                <span role="img" aria-label="Happy Face">😃</span>
                                                <b> All the best, Mauro. </b>
                                                <span role="img" aria-label="Waving Hand">👋️</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default Contact;
