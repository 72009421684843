import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

const imageSalaries = require('./../../images/salaries-argentina.png');

class SalaryInfoDevsArg extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Salaries for Developers in Argentina</title>
                    <meta name="description" content="Explore average developer salaries in Argentina, ranging from Junior to Senior levels, with data in USD." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/salaries-developer-argentina" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <Link to = {"/"}>
                                    <h1 className="text-white">
                                        Salary Information for Developers in Argentina
                                    </h1>
                                </Link>
                                <div className="breadcrumb-row">
                                    <ul className="">
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>	
                    <div className="content-area">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-2 col-md-2 m-3">
                                    <div className="blog-post blog-single blog-style-1">
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8 m-6">

                                    <div className="blog-post blog-single blog-style-1 p-l10 m-l10 p-r5 m-r5">
                                        <div className="dez-post-text">

                                            <section id="salaries">
                                                <h2>Salaries for Developers in Argentina <span role="img" aria-label="Argentina">🇦🇷</span></h2>
                                                <p>Explore average developer salaries in Argentina, ranging from Junior to Senior levels, with data in USD.</p>
                                                <h4>All salaries are presented and converted in USD</h4>
                                                <hr />
                                                <p><b><span role="img" aria-label="Junior">👶</span> Junior Developer: $ 1200 USD</b> (in a range between $500 to $1800 USD).</p>
                                                <hr />
                                                <p><b><span role="img" aria-label="Semi Senior">🧑‍💻</span> Semi Senior Developer: $ 2500 USD</b> (in a range between $1000 to $4000 USD).</p>
                                                <hr />
                                                <p><b><span role="img" aria-label="Senior">👨‍💼</span> Senior Developer: $ 5000 USD</b> (in a range between $2000 to $14000 USD).</p>
                                            </section>

                                            <hr />
                                            <br />

                                            <section id="data-source">
                                                <h3 style={{color: '#444444'}}>Data Source</h3>
                                                <p>
                                                    These salary data were collected through anonymous surveys conducted with developers in Argentina.
                                                </p>
                                                <p>
                                                    Sources: <b>SysArmy</b> (2024), <b>Salancy</b> (2024), <b>Reddit</b> /r/devsarg (2024), <b>CuantoGano .com</b> (2024), and other sources.
                                                </p>
                                                <p><b>Last update:</b> December 2024. <b>Based on:</b> +2000 salaries reported.</p>
                                                <p><small><b>Note:</b> This data is for informational purposes only. Salaries may vary depending on the specific location, company, and individual knowledge and experience.</small></p>
                                                <img src={imageSalaries} alt="Salaries for Developers in Argentina" />
                                            </section>

                                            <hr />
                                            <br />

                                            <h5>More data and information about salaries are coming soon...<span role="img" aria-label="Love">😃</span></h5>
                                            <p>
                                                Thank you for reading & best wishes if you are looking for a new job.
                                            </p>
                                            <p>
                                                <span role="img" aria-label="Wave">👋️</span> <b> Mauro.</b>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default SalaryInfoDevsArg;
