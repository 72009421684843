import React, { Component } from "react";
//import { Link } from "react-router-dom";
import Jobfindbox from "./../Element/Jobfindbox2";
import Footer from "./../Layout/Footer2";
import Header from "./../Layout/Header";
import Jobcategories from './../Element/Jobcategories';
import Featureblog from './../Element/Featureblog';
import UnlockJobs from './../Element/UnlockJobs';
import { Helmet } from 'react-helmet';
import ContentJobs from './../Element/ContentJobs';
import { getUrl, getHeaders } from './Api';
import './browsejobgrid.css';

const axios = require('axios');

class Browsejobgrid extends Component {
    constructor(props) {
        super(props);

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        this.state = {
            jobs: [],
            total: '',
            myparams: params,
            isLoading: true
        };
    }

    componentDidMount() {
        this.jobsList();
    }

    jobsList() {
        this.setState({ isLoading: true });
        
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const options = {
            method: "GET",
            url: getUrl(),
            params: {
                perPage: params.perPage || 20,
                page: params.page,
                title: params.title,
                location: params.location,
                salaryMin: params.salaryMin,
                tag: params.tag,
                company: params.company,
                source: params.source
            },
            headers: getHeaders()
        };

        axios.request(options)
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    isLoading: false,
                    error: 'Failed to load jobs. Please try again later.'
                });
            });
    }

    render() {
        var { jobs, total, page, pages, prevPage, nextPage, myparams, isLoading } = this.state;

        let showTopSearch = true;
        if (myparams.title || myparams.location || myparams.salaryMin || myparams.page) {
            showTopSearch = false;
        }

        return (

            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Remote Jobs | Find Remote Work Opportunities Worldwide | OpenToWorkRemote</title>
                    <meta 
                        name="description" 
                        content="Search thousands of remote jobs worldwide. Find and apply to the best remote work opportunities in software development, marketing, design, and more. Updated daily." 
                    />
                    <meta 
                        name="keywords" 
                        content="remote jobs, work from home, remote work, remote career, remote opportunities, remote positions" 
                    />
                    <link rel="canonical" href="https://www.opentoworkremote.com/" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="dez-bnr-inr bg-dark">
                        <div className="container">
                            <div className="dez-bnr-inr-entry text-center">
                                <a href="/" className="banner-link" rel="noopener noreferrer">
                                    <h1 className="text-white display-4 mb-4">
                                        Find Your Perfect Remote Job
                                    </h1>
                                </a>
                                <div className="breadcrumb-row">
                                    <ul className="banner-subtitle">
                                        <li className="lead text-light">
                                            Ready to work from anywhere? Find your dream job!
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Jobfindbox />
                    {showTopSearch && (
                    <div className="section-full job-categories content-inner-2 bg-white">
                        <div className="container">
                            <div className="section-head text-center">
                                <h2 className="m-b5">
                                    <span className="text-primary">Explore</span> Top Remote Searches
                                </h2>
                                <div className="separator">
                                    <span className="separator-line"></span>
                                </div>
                                <h5 className="fw4 m-t15">Find jobs categorized perfectly for you!</h5>
                            </div>
                            <Jobcategories />
                        </div>
                    </div>
                    )}

                    <ContentJobs 
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />
                </div>

                {showTopSearch && (
                    <Featureblog />
                )}

                {showTopSearch && (
                    <UnlockJobs />
                )}

                <Footer />
            </div>
        );
    }
}

export default Browsejobgrid;
