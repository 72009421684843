import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Header.css';

class Header extends Component{
    render() {
        return (
            <div>
                <header className="site-header mo-left header fullwidth">
                    <div className="sticky-header main-bar-wraper navbar-expand-lg">
                        <div className="main-bar clearfix">
                            <div className="container clearfix">
                                <div className="logo-job">
                                    <a href="/" className="brand-container">
                                        <div className="logo-wrapper">
                                            {/* <img
                                                src={require('./../../images/logo-navidad.png')}
                                                className="logo"
                                                alt="Logo Open To Work Remote"
                                                height="60"
                                                width="60"
                                            /> */}
                                            <img
                                                src={require('./../../images/logo-otwr-scaled.png')}
                                                className="logo"
                                                alt="Logo Open To Work Remote"
                                                height="60"
                                                width="60"
                                            />
                                        </div>
                                        <div className="brand-text">
                                            <span className="brand-name">Open To Work Remote</span>
                                            <span className="brand-tagline">Find Your Next Remote Job Here</span>
                                        </div>
                                    </a>
                                </div>
                                <div className="post-job desktop-only">
                                    <Link to={"/try-remote-jobs-in-your-inbox"} className="dez-tilte"><button className="site-button radius-l"><span role="img" aria-label="Unlock Now">🔓</span> Unlock All Jobs</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

export default Header;
