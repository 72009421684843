import React from 'react';
import { Link } from "react-router-dom";
import LogoComponent from './LogoComponent';
import Pagination from './Pagination';
import '../styles/skeleton.css';

const SkeletonJob = () => (
    <li className="col-lg-6">
        <div className="post-bx modern-card">
            <div className="d-flex">
                <div className="job-post-info">
                    <div className="company-header">
                        <div className="skeleton-logo"></div>
                        <div className="skeleton-company"></div>
                    </div>
                    
                    <div className="skeleton-title"></div>
                    
                    <div className="job-details">
                        <div className="detail-item">
                            <div className="skeleton-detail"></div>
                        </div>
                        <div className="detail-item">
                            <div className="skeleton-detail"></div>
                        </div>
                        <div className="detail-item">
                            <div className="skeleton-detail"></div>
                        </div>
                        <div className="detail-item">
                            <div className="skeleton-detail"></div>
                        </div>
                    </div>
                    
                    <div className="tags-container">
                        <div className="skeleton-tag"></div>
                        <div className="skeleton-tag"></div>
                        <div className="skeleton-tag"></div>
                    </div>
                </div>
            </div>
        </div>
    </li>
);

const ContentJobs = ({ jobs, total, page, pages, prevPage, nextPage, isLoading = false }) => {
    const totalJobs = total + " Jobs Found";

    if (page > pages) {
        page = pages;
    }

    const myparams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // Agregar el efecto para manejar las teclas
    React.useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
                return; // No navegamos si el usuario está escribiendo en un input
            }

            if (event.key === 'ArrowLeft' && prevPage) {
                window.location.href = prevPage;
            } else if (event.key === 'ArrowRight' && nextPage) {
                window.location.href = nextPage;
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        // Limpieza del evento cuando el componente se desmonta
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [prevPage, nextPage]);

    return (
        <div style={{ minHeight: '500px' }}>
            <div className="content-block">
                <div className="section-full bg-white browse-job p-b50">
                    <div className="container">
                        <div className="job-bx-title clearfix">
                            <div className="d-flex flex-column align-items-center mb-3">
                                <h5 className="font-weight-700 text-uppercase mb-3">
                                    {totalJobs}
                                </h5>
                                {total > 0 && (
                                    <Pagination 
                                        page={page}
                                        pages={pages}
                                        prevPage={prevPage}
                                        nextPage={nextPage}
                                    />
                                )}
                            </div>
                        </div>

                        {total === 0 && !isLoading && (
                            <div className="">
                                <h2 className="m-b5">Oops! No Jobs Found.</h2>
                                <br />
                                <h5 className="fw4">It looks like there are no jobs matching your filters.</h5>
                                <h5 className="fw4">Don't worry though, keep exploring and adjusting your search criteria for better results.</h5>
                                {myparams.title && myparams.location && (
                                    <div>
                                        <a href={"?title=" + myparams.title} className="dez-tilte">More Jobs in: {myparams.title}</a>
                                        <br />
                                        <a href={"?location=" + myparams.location} className="dez-tilte">More Jobs on: {myparams.location}</a>
                                        <br />
                                    </div>
                                )}
                                <Link to={"/all-jobs-by-category"} className="dez-tilte">
                                    Explore All Jobs By Category</Link>
                                <br />
                                <Link to={"/all-jobs-by-location"} className="dez-tilte">
                                    Explore All Jobs By Location</Link>
                            </div>
                        )}
                        <ul className="post-job-bx browse-job-grid row">
                            {isLoading ? (
                                // Mostrar skeletons mientras carga
                                [...Array(6)].map((_, index) => (
                                    <SkeletonJob key={index} />
                                ))
                            ) : (
                                // Mostrar jobs cuando ya están cargados
                                jobs.map((item, index) => (
                                    <li className={(item.featured ? "col-lg-6" : "col-lg-6")} key={index}>
                                        <Link to={`/view/${item.id}`}>
                                            <div className={"post-bx modern-card " + (item.featured ? "featured" : "")} style={{ height: '100%', minHeight: '320px' }}>
                                                <div className="d-flex">
                                                    <div className="job-post-info">
                                                        <div className="company-header">
                                                            <LogoComponent logoUrl={item.logo} />
                                                            <h3 className="company-name">{item["company"]}</h3>
                                                        </div>
                                                        
                                                        <h4 className="job-title">{item["title"]}</h4>
                                                        
                                                        <div className="job-details">
                                                            {item.salaryRange && (
                                                                <div className="detail-item">
                                                                    <i className="fa fa-money"></i>
                                                                    <span>{item["salaryRange"]}</span>
                                                                </div>
                                                            )}
                                                            {item.location && (
                                                                <div className="detail-item">
                                                                    <i className="fa fa-map-marker"></i>
                                                                    <span>{item["location"]}</span>
                                                                </div>
                                                            )}
                                                            <div className="detail-item">
                                                                <i className="fa fa-clock-o"></i>
                                                                <span>{item["publicationDate2"]}</span>
                                                            </div>
                                                            <div className="detail-item">
                                                                <i className="fa fa-window-restore"></i>
                                                                <span>{item["source"]}</span>
                                                            </div>
                                                        </div>
                                                        
                                                        {item.tags > "" && (
                                                            <div className="tags-container">
                                                                {item.tags.slice(0, 4).map((tag, i) => (
                                                                    <span key={i} className="tag">{tag}</span>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))
                            )}
                        </ul>
                        {total > 0 && (
                            <Pagination 
                                page={page}
                                pages={pages}
                                prevPage={prevPage}
                                nextPage={nextPage}
                            />
                        )}
                    </div>
                </div>
            </div>
            <style>
                {`
                    .modern-card {
                        background: white;
                        border-radius: 12px;
                        padding: 20px;
                        box-shadow: 0 2px 12px rgba(0,0,0,0.08);
                        position: relative;
                        transition: background-color 0.2s ease;
                    }

                    .modern-card.featured {
                        // background: #f0f7ff;
                        // background: #e1e7ff;
                        background: #FFD700;
                    }

                    .modern-card.featured:hover {
                        // background: #bac6f4;
                        background: #e9c500;
                    }

                    .modern-card.featured::before {
                        content: "🏅";
                        position: absolute;
                        top: 12px;
                        right: 12px;
                        font-size: 1.2rem;
                    }

                    .company-header {
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;
                    }

                    .company-name {
                        margin-left: 12px;
                        font-size: 1.2rem;
                        color: #333;
                    }

                    .job-title {
                        font-size: 1.4rem;
                        color: #1a1a1a;
                        margin-bottom: 16px;
                        font-weight: 600;
                    }

                    .job-details {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                        gap: 12px;
                        margin-bottom: 16px;
                    }

                    .detail-item {
                        display: flex;
                        align-items: center;
                        color: #666;
                    }

                    .detail-item i {
                        margin-right: 8px;
                        color: #007bff;
                    }

                    .tags-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;
                        margin-top: 16px;
                    }

                    .tag {
                        background: #f0f4f8;
                        padding: 4px 12px;
                        border-radius: 16px;
                        font-size: 0.9rem;
                        color: #4a5568;
                    }

                    .skeleton-logo,
                    .skeleton-company,
                    .skeleton-title,
                    .skeleton-detail,
                    .skeleton-tag {
                        background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
                        background-size: 200% 100%;
                        animation: loading 1.5s infinite;
                        border-radius: 4px;
                    }

                    .skeleton-logo {
                        width: 48px;
                        height: 48px;
                        border-radius: 8px;
                    }

                    .skeleton-company {
                        height: 20px;
                        width: 160px;
                        margin-left: 12px;
                    }

                    .skeleton-title {
                        height: 24px;
                        width: 80%;
                        margin: 16px 0;
                    }

                    .skeleton-detail {
                        height: 16px;
                        width: 140px;
                    }

                    .skeleton-tag {
                        height: 24px;
                        width: 80px;
                        border-radius: 16px;
                    }

                    @keyframes loading {
                        0% { background-position: 200% 0; }
                        100% { background-position: -200% 0; }
                    }

                    .post-job-bx a {
                        text-decoration: none;
                    }

                    .post-job-bx a:hover {
                        text-decoration: none;
                    }

                    .pagination-counter {
                        background: #f8fafc;
                        border-radius: 20px;
                        padding: 8px 24px;
                        font-size: 1.1rem;
                        margin: 0 16px;
                        color: #1a202c;
                        display: inline-flex;
                        align-items: center;
                        gap: 8px;
                    }

                    .pagination-counter .current {
                        font-weight: 700;
                        color: #007bff;
                    }

                    .pagination-counter .separator {
                        color: #cbd5e0;
                        font-weight: 300;
                    }

                    .pagination-counter .total {
                        color: #718096;
                    }

                    .navigation-link {
                        width: 40px;
                        height: 40px;
                        padding: 0 !important;
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50% !important;
                        background: white !important;
                        border: 2px solid #e2e8f0 !important;
                        color: #2d3748 !important;
                        transition: all 0.2s ease;
                    }

                    .navigation-link:hover {
                        background: #007bff !important;
                        border-color: #007bff !important;
                        color: white !important;
                        transform: scale(1.1);
                    }

                    .navigation-link i {
                        font-size: 0.9rem;
                    }

                    .page-item {
                        margin: 0 4px;
                    }

                    @media (max-width: 576px) {
                        .pagination-counter {
                            padding: 6px 16px;
                            font-size: 1rem;
                        }

                        .navigation-link {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    .keyboard-hint {
                        font-size: 0.85rem;
                        color: #718096;
                        opacity: 0.8;
                        transition: opacity 0.3s ease;
                    }

                    .pagination-bx:hover .keyboard-hint {
                        opacity: 1;
                    }

                    @media (max-width: 576px) {
                        .keyboard-hint {
                            display: none;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default ContentJobs;
